import React,{useEffect} from 'react'
export const Industries = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
    const service = [
      {
        id: 1,
        img: `./assets/icon/automobile.png`,
        heading: `Automobile`,
        des: `Techsaga professionals deliver result-oriented automotive software development service`,
      },
      {
        id: 2,
        img: `./assets/icon/realestate.png`,
        heading: `Real Estate`,
        des: `Accelerate your online business with software development solutions from Techsaga`,
      },
      {
        id: 3,
        img: `./assets/icon/telecommunication.png`,
        heading: `Telecommunication`,
        des: `Bringing new rapid advancements through evolving telecom IT services.`,
      },
      {
        id: 4,
        img: `./assets/icon/media.png`,
        heading: `Media and entertainment`,
        des: `Techsaga offers industry- leading digital-infused solutions for corporate brand management`,
      },
      {
        id: 5,
        img: `./assets/icon/fork.png`,
        heading: `Food and FMCG`,
        des: `Delivering seamless IT solutions from start to finish. With turnkey retail IT solutions from Techsaga`,
      },
      {
        id: 6,
        img: `./assets/icon/healthcare.png`,
        heading: `Healthcare`,
        des: `Techsaga enables healthcare enterprises to innovate and improve healthcare industry by building custom medical solutions.`,
      },
      {
        id: 7,
        img: `./assets/icon/logistics.png`,
        heading: `Logistics and Transportation`,
        des: `Techsaga employs smart contracts, blockchain solutions coupled with IOT sensors to automate delivery in logistics`,
      },
      {
        id: 8,
        img: `./assets/icon/manufacture.png`,
        heading: `Manufacturing`,
        des: `Digitise and embrace your manufacturing industry operations and management with tech-driven IT solutions from Techsaga.`
      },

      {
        id: 9,
        img: `./assets/icon/passport.png`,
        heading: `Travel & Tourism`,
        des: `Leverage our end-to-end technology solutions, impactful mobile applications to comprehensive custom-made systems`,
      },
      {
        id: 10,
        img: `./assets/icon/vendor.png`,
        heading: `Independent Software Vendors`,
        des: `Techsaga has earned reputation with its decade old expertise in producing scalable next-generation software`,
      },
      {
        id: 11,
        img: `./assets/icon/bankiing.png`,
        heading: `Banking and Finance`,
        des: `We create market-ready FinTech applications for varied customer demands.`,
      },
      {
        id: 12,
        img: `./assets/icon/video.png`,
        heading: `Education and E-Learning`,
        des: `With industry specific experience, Techsaga has proven expertise in building eLearning solutions`,
      },
      {
        id: 13,
        img: `./assets/icon/online-shop.png`,
        heading: `E-Commerce Solutions`,
        des: `Disrupting retail software and application through robust automated platforms.`,
      },
      {
        id: 14,
        img: `./assets/icon/sorting.png`,
        heading: `Food Industry`,
        des: `Embrace the new-age digital world through our comprehensive IT solutions and dynamic web applications`,
      },
    ]
    return (
      <section className="inddustries pt-top pt-btm">
        <div className="container">
          <div className="title">
              OUR INDUSTRIES
          </div>
          <h2>We Ideate, Innovate & Create Technology Solutions in varied Industries</h2>
          <div className="flex">
            {
              service.map((indEl) => {
                return(
                  <div className="industries-wrap" key={indEl.id}>
                    <div className="icon-part">
                      <img src={indEl.img} alt="" />
                    </div>
                    <div className="content-part">
                      <h4>{indEl.heading}</h4>
                      <div className="indus-para">
                      <p className="desc">{indEl.des}</p>

                      </div>
                      {/* <div className="btn-part">
                        <a href="/" target="_blank">Learn More</a>
                      </div> */}
                    </div>
                  </div>
                )
              })
            }
          </div>
        </div>
      </section>
    )
  }
