import {useEffect} from 'react'
const sixcompo=[
    {
    Id:1,
    button:`Managed Services`,
    id:`#ms`,

  },
  {
    Id:2,
    button:`Optimizations`,
    id:`#ms1`,
  },
  {
    Id:3,
    button:`Integrations`,
    id:`#ms2`,
  },
  {
    Id:4,
    button:`Implementations`,
    id:`#ms3`,
  },
  {
    Id:5,
    button:`Advisory Services`,
    id:`#ms4`,
  },
  {
    Id:6,
    button:`Clients`,
    id:`#ms5`,
  }
]

export const SixCompo = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className="sixcompo">
       <div className="flex">
         {
             sixcompo.map((curEl)=>{
                return(
                    <div className='mainbox' key={curEl.Id}>
                       
                        <a href={curEl.id} className="box">{curEl.button}</a>
                    </div>
                )
             })
         }
       </div>
    </section>
  )
}
