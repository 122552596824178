import React,{useEffect} from 'react'
import { ServiceSidebar } from './ServiceSidebar'
import { ServicecontactBox } from './ServicecontactBox'
import { RelatedService} from './RelatedService'


import { FaCheckCircle } from 'react-icons/fa';
export const InfluentialMarketing = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className="agile pt-top pt-btm">
         <div className="container flex spce-btwn">
               <div className="left">
                    <ServiceSidebar/>
                    <ServicecontactBox/>
                    
               </div>
               <div className="right">
                     <div className="contant-box">
                         <img src="./assets/img/influencer-marketing-platforms.webp" alt="agile information" className='img-fluid mt-35'/>
                         <h3>Influential Marketing</h3>
                         <p>Influence Marketing connecting brands to social media influencers to tell great stories. Techsaga is a leading Influencer marketing agency which provides the best influencer marketing platform to help brands / visionary marketers leverage social media influencers’ content in marketing the product or services. We enable the brands to analyse influencer's performance on different social media channels.Techsaga is a leading influencer marketing agency that connects businesses to target audiences through social media influencers.</p>
                         <h5>Get Measurable Results with Techsaga </h5>
                         <p>Techsaga assists clients enable them to compete with their competitors and build a fast-growing business. Brands can gain credibility by the means of Influential Marketing and we help them in achieving measurable results. Our content creators and marketing experts create unique content and devise the comprehensive influencer marketing strategy to utilize the trusted relationship between influencers and their followers to position your product at a right place in the market.This way, we help businesses in increasing their awareness, leads and sales through positive engagement to target audience.</p>
                         <h5>Targeted and Authentic Influential Marketing</h5>
                         <p>For campaigning of brands we shortlist the right social media influencers after analyzing their engagement rates, followers base, performance metric, past campaign performances. We analyze the target audience interest of the influencers of the brand for maximizing the influence as well as return on investment. We help visionary marketers or new industry players discover and collaborate with creators, bloggers, social media influencers, and passionate fans of their brand. Brands benefit by gaining customer attention, real engagement and growing their business. We utilize data driven approach and leverage people powered content, referrals and marketing.</p>
                         <h5>How We Do Influencer Marketing</h5>
                         <p>We find the right influencers for your brand, to get a high engagement rate. From hiring top creators with a global reach to hiring  micro-influencers, Techsaga make sure to onboard content creators who share your brand values. Techsaga levarges engaging, creative and captivating influencer marketing campaigns to attract and inform audience.</p>
                         <h5>Industry Research</h5>
                         <p>Before we create a campaign strategy for your business, we research the best social media platforms and the most influential content creators for your business. </p>
                         <h5>Influencer Pitching</h5>
                         <p>Techsaga has a huge network of influencers and pitch influencers explaining them the benefits of working together.</p>
                         <h5>Partner Negotiations</h5>
                         <p>Techsaga designs a campaigns with right influencers to create a engaging campaign to help you achieve your business goals.</p>

                         
                         
                     </div>
                     <RelatedService/>
               </div>
         </div>
    </section>
  )
}

