import React,{useState,useEffect,useRef} from 'react';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from "react-google-recaptcha";
import swal from 'sweetalert';
// toast.configure();
export var InnerContact = () => {
     useEffect(() => {
          window.scrollTo(0, 0)
        }, [])
     var [form,setForm]=useState({userFname:'',userLastname:'',userPhone:'',userEmail:'',userCompany:'',userWebsite:'',userContact:'',userCity:'',userCaptcha:''})
     var fnameChangeHandler =(events) =>{
      setForm((prevState)=>{
               return {...prevState,userFname:events.target.value} 
          })
          
     }
     var lnameChangeHandler =(events) =>{
         
          setForm((prevState)=>{
               return {...prevState,userLastname:events.target.value} 
          })
     }
     var phoneChangeHandler =(events) =>{
          
          setForm((prevState)=>{
               return {...prevState,userPhone:events.target.value} 
          })
     }
     var emailChangeHandler =(events) =>{
         
          setForm((prevState)=>{
               return {...prevState,userEmail:events.target.value} 
          })
     }
     var companyChangeHandler =(events) =>{
         
          setForm((prevState)=>{
               return {...prevState,userCompany:events.target.value} 
          })
     }
     var websiteChangeHandler =(events) =>{
         
          setForm((prevState)=>{
               return {...prevState,userWebsite:events.target.value} 
          })
     }
     var contacttimeChangeHandler =(events) =>{
         
          setForm((prevState)=>{
               return {...prevState,userContact:events.target.value} 
          })
     }
     var cityChangeHandler =(events) =>{
         
          setForm((prevState)=>{
               return {...prevState,userCity:events.target.value} 
          })
     }
     const captchaRef = useRef(null)
     
     // var captchaChangeHandler =(events) => {
          
          // setForm((prevState)=>{
          //      return {...prevState,userCaptcha:events.target.value} 
          // })
     //    }
     var validation =()=>{
         document.getElementsByClassName("error-level").innerHTML='&nbsp;';
     //     var e = document.getElementById("phone").value;
         var errorCount = 0;
     //     if (e.length==8 || e.length==10 || e.length==12){
     //      document.getElementById("message5").innerHTML="";
     //     }
     //     else{
     //           document.getElementById("message5").innerHTML="Enter valid number";
     //           errorCount =1;
     //     }
         var correctWay = /^[a-zA-Z ]*$/;
         var a = document.getElementById("firstname").value;
         var b = document.getElementById("lastname").value;
         var c = document.getElementById("companyname").value;
         var d = document.getElementById("city").value;
         
          if(a.match(correctWay)){
               document.getElementById("message").innerHTML="";
          }
          else{
             document.getElementById("message").innerHTML="Only alphabets are allowed";
             errorCount =1;
          }

          if(b.match(correctWay)){
               document.getElementById("message2").innerHTML="";
          }
          else{
               document.getElementById("message2").innerHTML="Only alphabets are allowed";
               errorCount =1;
          }
          if(c.match(correctWay)){
               document.getElementById("message3").innerHTML="";
          }
          else{
               document.getElementById("message3").innerHTML="Only alphabets are allowed";
               errorCount =1;
          }
          if(d.match(correctWay)){
               document.getElementById("message4").innerHTML="";
          }
          else{
               document.getElementById("message4").innerHTML="Only alphabets are allowed";
               errorCount =1;
          }
          const token = captchaRef.current.getValue();
          if(token==''){
               document.getElementById("message6").innerHTML="Sorry Google Recaptcha Unsuccessful!!";
               errorCount =1;
          }
          else{
               document.getElementById("message6").innerHTML="";
          }
          return errorCount;
         
     }
     
     
     var submitHandler=(events)=>{
          
          events.preventDefault();
          
        
         
          var fname = form.userFname;
          var lname = form.userLastname;
          var email = form.userEmail;
          var phone = form.userPhone;
          var company = form.userCompany;
          var website = form.userWebsite;
          var contact = form.userContact;
          var city = form.userCity;
          var check = validation();
          if(check==1)
          {
               return false;
          }

          axios.get('https://techsaga.us/API/api/sendContactData?fname='+fname+'&lname='+lname+'&email='+email+'&phone='+phone+'&company='+company+'&website='+website+'&time='+contact+'&city='+city+'').then((response) => {
               setForm(response.data);
              
          return true;
          });
          


         
          cancelCourse();
          swal("THANK YOU FOR CONTACTING US !", "We will revert you shortly !", "success");
          // notify();
          captchaRef.current.reset();
          // alert("Your Enquiry Has Been Send Successfully !");
       
    
     }
     const notify = ()=>{
 
          // Calling toast method by passing string
          toast('Your enquiry has been sent successfully');
      }
     var cancelCourse = () => { 
          document.getElementById("create-course-form").reset();
        }
        
        
    return (
        <section className="InnerContact">
            <div className="flex spce-btwn">
                <div className="img">
                    <img src="./assets/img/innder_contact.webp" alt=""  className='img-fluid h-100'/>
                </div>
                <div className="contant">
                     <span>LET'S TALK</span>
                     <h2>Request a Free Quote</h2>

                    <form id="create-course-form" onSubmit={submitHandler}>
                        <div className="flex spce-btwn">
                                <div className="form-group">
                                     <input type="text" className="form-control"  id="firstname" name="firstname" placeholder="First Name" onChange={fnameChangeHandler} required/>
                                     <span id="message" className="error-level"></span>
                                    
                                </div>
                                <div className="form-group">
                                     <input type="text" className="form-control"  id="lastname" placeholder="Last Name" onChange={lnameChangeHandler}/>
                                     <span id="message2" className="error-level"></span>
                                </div>
                                <div className="form-group">
                              <input type="number" className="form-control" id="phone" placeholder="Phone Number" maxlength="12" onChange={phoneChangeHandler} required/>
                              <span id="message5" className="error-level"></span>
                                </div>
                                <div className="form-group">
                                     <input type="email" className="form-control"  placeholder="Email Id" onChange={emailChangeHandler} required/>
                                </div>
                                <div className="form-group">
                                     <input type="text" className="form-control"  id="companyname" placeholder="Company Name" onChange={companyChangeHandler} required/>
                                     <span id="message3" className="error-level"></span>
                                </div>
                                <div className="form-group">
                                     <input type="text" className="form-control"  placeholder="Website Url" onChange={websiteChangeHandler}/>
                                </div>
                                <div className="form-group" style={{width:"100%"}}> 
                                      {/* <label>When is the best time to contact you? *</label> */}
                                     <input type="text" className="form-control"  placeholder="When is the best time to contact you?" onChange={contacttimeChangeHandler} required/>
                                </div>
                                <div className="form-group" style={{width:"100%"}}>
                                      {/* <label>What is your city?(so we know your time zone) *</label> */}
                                     <input type="text" className="form-control"  id="city" placeholder="What is your city?(so we know your time zone)" onChange={cityChangeHandler} required/>
                                     <span id="message4" className="error-level"></span>
                                </div>
                                <div className="form-group" style={{width:"100%"}}>
                                <ReCAPTCHA
                                   sitekey="6LdOLjoiAAAAAEArKrtuJVb-4h-KqzVa7QEzmrs0"
                                   ref={captchaRef}
                                   />
                                       <span id="message6" className="error-level"></span>
                                </div>
                                <input type="submit" className="submit"/>
                                <ToastContainer />
                        </div>
                    </form>
                </div>
            </div>
     </section>
  )
}
