const blogs = [
    {
      id: 1,
      img: `./assets/img/blog.webp`,
      mintitle: `Marketing`,
      heading: `Digital Marketing Strategies For Education Industry`,
      des: `The education industry is one of the worst-hit sectors, amidst pandemic outbreaks, and had no chance to survive with just conventional marketing. Millennials lean towards e-learning tools to keep up with their educational goals, as traditional classrooms remain closed in most parts of the world....`,
      date: `06 Sep 2019`,
    },
    {
      id: 2,
      img: `./assets/img/blog.webp`,
      mintitle: `Outsource`,
      heading: `Website blog-Dedicated Team Model: Outsource Immediately to Scale Your Business`,
      des: `The business world is getting extremely competitive due to technological advancements, and hence maintaining continuity is crucial and becoming a major priority for organizations with the aim of substantial cost savings...`,
      date: `06 Sep 2019`,
    },

    {
      id: 3,
      img: `./assets/img/blog.webp`,
      mintitle: `Management`,
      heading: `Top 5 Tips To Set Up Effective Delivery With An Offshore Outsourcing Company`,
      des: `Top 5 Tips To Set Up Effective Delivery With An Offshore Outsourcing CompanyWith the proliferation of digital outsourcing of software services, it becomes a necessity for businesses to shorten software delivery times to stay profitable and competitive at the same time....`,
      date: `06 Sep 2019`,
    },
    {
        id: 4,
        img: `./assets/img/blog.webp`,
        mintitle: `Management`,
        heading: `Soundtrack filma Lady Exclusive Music`,
        des: `We denounce with righteous indige nation and dislike men who are so beguiled and demo realized by...`,
        date: `06 Sep 2019`,
      },
      {
        id: 5,
        img: `./assets/img/blog.webp`,
        mintitle: `Management`,
        heading: `Soundtrack filma Lady Exclusive Music`,
        des: `We denounce with righteous indige nation and dislike men who are so beguiled and demo realized by...`,
        date: `06 Sep 2019`,
      },
      {
        id: 6,
        img: `./assets/img/blog.webp`,
        mintitle: `Management`,
        heading: `Soundtrack filma Lady Exclusive Music`,
        des: `We denounce with righteous indige nation and dislike men who are so beguiled and demo realized by...`,
        date: `06 Sep 2019`,
      },
  ];
  export default blogs;
