import React,{useEffect} from 'react';
import { FaPhoneSquareAlt,FaMailBulk,FaLocationArrow } from "react-icons/fa";
export const MainContact = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
//    const maincontact=[{
//           Id:1,
//           fa:<FaPhoneSquareAlt/>,
//           heading:`Phone Number`,
//           para:`+1(856) 888-9026`,
//         },
//         {
//             id:2,
//             fa:<FaMailBulk />,
//             heading:`Email Address`,
//             para:`info@techsaga.us`,
//      },
//      {
//         Id:3,
//         fa:<FaLocationArrow />,
//         heading:`Office Address`,
//         para:`2728 Steece way Leander, TX 78641 United States`,
//  },

        
//         ]


  return (
 <section className="maincontact pt-top mb-35">
 <div className="container">
  <div className="company-info">
     <div className="flex spce-btwn">
         <div className="box">
              <span className='fa'><FaPhoneSquareAlt/></span>
              <h4>Phone Number</h4>
              <p><a href="tel:+1(856) 888-9026" className="phone-email">+1(856) 888-9026</a></p>
            </div>
           <div className="box">
              <span className='fa'><FaMailBulk/></span>
              <h4>Email Address</h4>
              <p><a href="mailto:info@techsaga.us" className="phone-email">info@techsaga.us</a></p>
            </div>
            <div className="box">
                <span className='fa'><FaLocationArrow /></span>
                <h4>Office Address</h4>
                <p>2728 Steece way Leander, TX 78641 United States</p>
            </div>
      </div>    
           
   </div>
 </div>
 </section>
  )
}
