import React,{useEffect} from 'react'
import { ServiceSidebar } from './ServiceSidebar'
import { ServicecontactBox } from './ServicecontactBox'
import { RelatedService} from './RelatedService'


import { FaCheckCircle } from 'react-icons/fa';
export const ManagedOutsourcingService = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className="agile pt-top pt-btm">
         <div className="container flex spce-btwn">
               <div className="left">
                    <ServiceSidebar/>
                    <ServicecontactBox/>
                    
               </div>
               <div className="right">
                     <div className="contant-box">
                         <img src="./assets/img/Manage-outsourcing1.jpeg" alt="agile information" className='img-fluid mt-35'/>
                         <h3>Managed Outsourcing Service</h3>
                         <p>Techsaga team helps you to achieve your product development goals. Our engineers become an integral part of your development cycle, thus frees you to focus on strategy, helps in business expansion and increase profitability. With our managed outsourcing model, we provide software engineers based on expertise and requirement during the entire project.</p>
                        <h5>Get more efficient with smart IT outsourcing</h5>
                         <p>With 12+ years of expertise, Techsaga can optimize the quality and cost-effectiveness of IT operations. Techsaga can take over any project task you want to outsource. By outsourcing critical software components, you can plan and implement digital product engineering transformation initiatives in a better way.  The Managed Outsourcing model is intended to find a highly-skilled software engineers with the right expertise and knowledge required for a specific software development project needs.</p>
                        <h4>Why Techsaga?</h4>
                        <h5>Cost Effective</h5>
                        <p>Our hiring services are aimed to be cost-effective, with focus on quality and productivity. Our engagement models provide best-in class software resources and are trained at maximizing ROI.</p>
                        <h5>Knowledge of Proven Frameworks</h5>
                        <p>Our developers relies on tested and proven frameworks. Focus on offering a perfect solution to accommodate your business objectives.</p>
                        <h5>Scalable Team</h5>
                        <p>Make your business success-ready with an extremely flexible team. Our team leave no loose ends and are always on the same page with the client.</p>
                        <h5>24/7 Technical Support</h5>
                        <p>As a leading managed outsourced service provider, our service promotes quality driven support.</p>
                        <h5>Focus on Quality Driven Processes</h5>
                        <p>We are committed to high standards of performances and ensure this, by following a process driven approach.</p>
                        <p>Our accomplished and dedicated software/web developers have been consistently providing customized, comprehensive solutions in the various fields.</p>

                         
                     </div>
                     <RelatedService/>
               </div>
         </div>
    </section>
  )
}

