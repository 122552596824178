import React,{useEffect} from 'react'
import { ServiceSidebar } from './ServiceSidebar'
import { ServicecontactBox } from './ServicecontactBox'
import { RelatedService} from './RelatedService'


import { FaCheckCircle } from 'react-icons/fa';
export const StartupIncubation = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className="agile pt-top pt-btm">
         <div className="container flex spce-btwn">
               <div className="left">
                    <ServiceSidebar/>
                    <ServicecontactBox/>
                    
               </div>
               <div className="right">
                     <div className="contant-box">
                         <img src="./assets/img/startup-icubebation-services.jpg" alt="agile information" className='img-fluid mt-35'/>
                         <h3>Startup Incubation</h3>
                         <p>Get end-to-end Incubation services from Techsaga for your business. With our Startup Incubation services, you get all the assistance to jumpstart your business required to stand against competitors in the market.</p>
                        <h5>Startup Incubation Services Tailored To Your Needs</h5>
                         <ul>
                           <li><FaCheckCircle/> Business Consulting</li>
                           <li><FaCheckCircle/> Latest Technology Support</li>
                           <li><FaCheckCircle/> Business Strategies</li>
                           <li><FaCheckCircle/> Business Ideation</li>
                           <li><FaCheckCircle/> Mentoring and Advisory Services</li>
                         </ul>
                       <h4>We deliver exemplary services to startups through </h4>
                       <h5>Branding</h5>
                         <p>Brand is the identity of startup and our services will give a voice to your startup. Techsaga will help you transforming your idea into reality, enables you to establish a prominent position in the market.</p>
                         <h5>Prototyping</h5>
                         <p>Techsaga design and develop prototype products for startups, offering expertise to deliver an ideal path for your dream project.</p>
                         <h5>MVP (Minimum Viable Product)</h5>
                         <p>At Techsaga, we offer MVP software development services that provides efficient MVP to the end-user. Our seamless solutions analyze your product and offer you viable solutions that your new-business requires.</p>
                         <h4>Advantages Of Our Startup Incubation Services Mentorship</h4>
                         <p>Techsaga offers real-time support and proper guidance for taking off your business to a right start.</p>
                         <h5>Advisory Services</h5>
                         <p>With startup incubator’s advisory, Techsaga helps to bridge gap through experience to make better decisions.</p>
                         <h5>Money Saver</h5>
                         <p>Startups and early-stage businesses requires assistance in day-to-day operating activities.</p>
                         <h5>Access To Expertise</h5>
                        <p>Being in the industry for years, techsaga offers right technical expertise and experience to the incubator.</p>


                         
                     </div>
                     <RelatedService/>
               </div>
         </div>
    </section>
  )
}

