import React from 'react'
const Fourservices = () => {
    const min_service =[
        {
            id:1,
            icon:`./assets/icon/1.png`,
            title:`IT Staffing & Recruiting`,
            des:`Techsaga's group of sharpened recruiters has positioned heaps of professional applicants withinside the IT marketplace to attain the commercial enterprise desires of our clients.`,
        },
        {
            id:2,
            icon:`./assets/icon/2.png`,
            title:`Software Quality Assurance`,
            des:`Techsaga initiates to gain information at the root level answering the ‘WHY’ of the product, improving its lifecycle by constantly uncovering the discrepancies.`,
        },
        {
            id:3,
            icon:`./assets/icon/3.png`,
            title:`Managed Support Services`,
            des:`Techsaga is a helping hand to numerous businesses to preserve vital capabilities aiming to acquire their enterprise bottom-line growth.`,
        },
        {
            id:4,
            icon:`./assets/icon/4.png`,
            title:`Web Application Development`,
            des:`Techsaga unfastens the technological boundaries & developmental analytics exponentially with the aid of sustained custom net software.`,
        },
    ]
    return (

        <section className="four-service-box pt-btm pt-top">
        <div className="container">
            <div className="flex spce-btwn align-center">
                    <div className="left">
                       <img src="./assets/img/four-service.webp" alt='hello' className="img-fluid" />
                   </div> 
                   <div className="right flex spce-btwn">
                     {min_service.map((serEl)=>
                         {
                             return(
                                <div className="box text-center" key={serEl.id}>
                                <img src={serEl.icon} alt="service box" />
                                <h4>{serEl.title}</h4>
                                <p>{serEl.des}</p>
                            </div>
                             )
                         }
                     )
                     }
                 </div>
            </div>
        </div>
    </section>
    )
}

export default Fourservices;

