import React,{useEffect} from 'react'
import { ServiceSidebar } from './ServiceSidebar'
import { ServicecontactBox } from './ServicecontactBox'
import { RelatedService} from './RelatedService'


import { FaCheckCircle } from 'react-icons/fa';
export const SoftwareDesignAndDevelopment = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className="agile pt-top pt-btm">
         <div className="container flex spce-btwn">
               <div className="left">
                    <ServiceSidebar/>
                    <ServicecontactBox/>
                    
               </div>
               <div className="right">
                     <div className="contant-box">
                         <img src="./assets/img/sd&d.webp" alt="agile information" className='img-fluid mt-35'/>
                         <h3>Software Design And Development</h3>
                         <p>Techsaga is a top Indian software development company that builds customized software as per business needs. We understand how important this process is, and have helped hundreds of clients by developing custom software applications to improve their business processes and get fixed ROI. Techsaga holds an excellent record in  significantly improving software development efficiency and helped its clients to save money and time.</p>
                         <p>Our unbreakable relationship with clients doesn’t end with delivering projects. With cost-effective software maintenance plans, Techsaga harnessing the power of digital technology to deliver the best performance around the clock.</p>
                         <p>With the help of software development solutions, Techsaga help you to build high-quality software and products as well as deliver a wide range of related professional services for the benefit of mid-to-large enterprises, and startups across a variety of industries.</p>
                         <p>Techsaga caters mid-sized and large enterprises supporting their business growth and address the unique needs by providing enterprise-customised software solutions. Through consulting services, Techsaga execute the entire strategy from ideation and feasibility study to implementation strategy.</p>
                         <h5>Here at Techsaga, we build, scale, and upgrade your solutions.</h5>
                        <p>Providing extensive range of growth-oriented web development solutions assures quality website solutions to our clients.</p>
                        <h5>Web App Development</h5>
                        <p>Our custom website design & development company turns your ideas into reality. It is our core competency to provide robust solutions that meet clients’ needs and fulfills all the business requirements. Whether it’s third-party API integration or custom business applications, Techsaga developers tailored solutions.</p>
                        <h5>Web Portal Development</h5>
                        <p>With our remarkable services, Techsaga develops state-of-the-art software development services for diverse business verticals. Our developers utilize the latest web technologies to create advanced web portals like enterprise portals, job portals, online travel portals, real estate portals, and more.</p>
                        <h5>eCommerce Web Development</h5>
                        <p>Techsaga has rigorous experience in developing full-scale e-commerce websites and multi-vendor marketplaces. Techsaga use CMS/Platforms and advanced technologies like Magento, WordPress+WooCommerce, PrestaShop, BigCommerce, Shopify, osCommerce, and more. We integrate the right security features, right plugins/extensions, and functionality to your e-commerce website or application.</p>
                        <h5>CMS Based Websites</h5>
                        <p>Techsaga is a pioneer in open-source content management systems like WordPress, Drupal, Joomla, Magento, etc, building cutting-edge websites for different types of businesses. Our developers can help choose the best CMS for your website and build the right solution that meets your expectations.</p>
                        <h5>PWA Development</h5>
                        <p>Techsaga has an extensive experience in building advanced progressive web apps with extensive features and functionality. Techsaga employs the latest technologies and craft solutions to build full-scale progressive web apps. </p>
                         
                     </div>
                     <RelatedService/>
               </div>
         </div>
    </section>
  )
}

