import React from 'react'
import { FaPhoneSquareAlt,FaMailBulk,FaFileDownload } from 'react-icons/fa';
import {Link} from "react-router-dom";
export const ServicecontactBox = () => {

  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch('Techsaga-Company-Profile.pdf').then(response => {
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = 'Techsaga-Company-Profile.pdf';
            alink.click();
        })
    })
}
  return (
    <>
    <section className='service-contact-box'>
          <div>
            <h3>Do not hesitate to contact us</h3>
            <div className="flex align-center">
                  <FaPhoneSquareAlt/>
                  <div className='mail-phone'>
                      <span>Call Us</span>
                      <h4><a href="tel:+1(856) 888-9026" className='services-contact'>+1(856) 888-9026</a></h4>
                  </div>  
            </div>
            <div className="flex align-center">
                  <FaMailBulk/>
                  <div className='mail-phone'>
                      <span>Email</span>
                      <h4><a href="mailto:info@techsaga.us" className='services-contact'>info@techsaga.us</a></h4>
                  </div>
            </div>
          </div>
   </section>
   <section className='brochure'>
        <h3>Brochure</h3>
             {/* <Link onClick={onButtonClick}><div className="sidebar-box flex align-center">
                    <FaFileDownload/>
                     <h4>Download Brochure</h4>
                    </div>
                    </Link> */}
                    <button className="sidebar-box flex align-center" onClick={onButtonClick}>
                    <FaFileDownload/><h4>Download Brochure</h4>
                </button>
   </section>
    
    </>
  )
}
