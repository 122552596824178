import React,{useEffect} from 'react'
import { ServiceSidebar } from './ServiceSidebar'
import { ServicecontactBox } from './ServicecontactBox'
import { RelatedService} from './RelatedService'


import { FaCheckCircle } from 'react-icons/fa';
export const HireDeveloper = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className="agile pt-top pt-btm">
         <div className="container flex spce-btwn">
               <div className="left">
                    <ServiceSidebar/>
                    <ServicecontactBox/>
                </div>
               <div className="right">
                     <div className="contant-box">
                         <img src="./assets/img/hire-developers1.jpg" alt="agile information" className='img-fluid mt-35'/>
                         <h3>DEDICATED DEVELOPMENT TEAM</h3>
                         <p>Leverage Techsaga’s process of building and managing dedicated team of developers. Scale your delivery capacity with intelligent dedicated developers, leading companies across industries with their projects. Our dedicated development team relies on expertise, skills, and mindset. Techsaga solves unique business problems with digital product engineering solutions through deploying world-class Agile teams to scale your project complexities.</p>
                         <p>Techsaga is trusted by world’s leading brand, supports multiple clients in meeting their technology needs with industry’s best practices. As a result, we execute and implement a standard dedicated development team with intelligent framework.</p>
                         <h4>Your Full-Stack IT Outsourcing Partner</h4>
                         <h5>Managed Agile Teams</h5>
                         <p>Our hire a developer model of remote agile teams have vetted engineers with a proven and successful track record. Our engagement models maintains company culture and work in line with your strategic goals.</p>
                         <h5>Complex Core Development</h5>
                         <p>To accelerate innovation and rapid change, our dedicated developers works on large-scale peripheral engineering tasks in a high growth environment.</p>
                         <h5>Scale Without Limits</h5>
                         <p>Our flexible dedicated developers transforms your engineering from being bottlenecks to growth drivers. From solving the critical business challenges, to aligning customer needs, engineers can bring desired organizational challenges. Easily ramp up, scale and pivot quickly, with minimal friction.</p>
                         <h5>Areas of Responsibility</h5>
                         <ul>
                           <li><FaCheckCircle/> Resource planning, development of team formation</li>
                           <li><FaCheckCircle/> Staff motivation and personal development plan</li>
                           <li><FaCheckCircle/> Development of technical training programs</li>
                           <li><FaCheckCircle/> Staff replacement and knowledge transfer</li>
                           <li><FaCheckCircle/>Provision of standard office and IT infrastructure</li>
                         </ul>
                     
                     
                         
                     </div>
                     <RelatedService/>
               </div>
         </div>
    </section>
  )
}

