import React from "react";
import { FaQuoteLeft } from "react-icons/fa";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Testimonial = () => {
  const testominal = [
    {
      id: 1,
      para: `The way Techsaga initiate the project understood discussions and helped us shape the online solution for our requirements is simply amazing. The team is brilliant.`,
      img:`./assets/img/avtar.webp`,
      name:`Lisa Fedro`,
      // place:`CEO, Keen IT Solution`,
    },
    {
      id: 2,
      para: `Web Design Enterprise is just great. When I have a question they answer it at once, they have more than outstanding customer service.`,
      img:`./assets/img/test3.webp`,
      name:`Noah`,
      // place:`CEO, Keen IT Solution`,
    },
    {
      id: 3,
      para: `Techsaga resides in the passion and enthusiasm of the smart working and skilled staff that is ambitious toward their profession. I’m very happy with their services.`,
      img:`./assets/img/test5.webp`,
      name:`Charlotte`,
      // place:`CEO, Keen IT Solution`,
    },
    {
      id: 4,
      para: `The company cares about its customers and tries to fulfill their needs with all its efforts and knowledge. `,
      img:`./assets/img/test2.webp`,
      name:`Elijah`,
      // place:`CEO, Keen IT Solution`,
    },
    {
      id: 5,
      para: `I believe in their values of keeping on the top of the priority list, working with devotion, and supplying the best standards approach. `,
      img:`./assets/img/test7.webp`,
      name:`James`,
      // place:`CEO, Keen IT Solution`,
    },
  ];

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <section className="testimonial pt-top pt-btm">
      <div className="container">
        <span className="title">TESTIMONIALS</span>
        <h2 className="text-center">What Saying Customers</h2>
        {/* <div className="flex spce-btwn"> */}
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlaySpeed={3000}
          arrows={false}
          autoPlay={true}
        >
          {testominal.map((curEle) => {
            return (
              <>
                <div className="test-item" key={curEle.id}>
                  <div className="box">
                    <FaQuoteLeft />
                    <p>{curEle.para}</p>
                  </div>
                  <div className="posted-by  flex align-center">
                    <div className="avtar">
                      <img src={curEle.img} alt="avtar" />
                    </div>
                    <div className="avatar-name">
                      <h4>{curEle.name}</h4>
                      {/* <p>{curEle.place}</p> */}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </Carousel>
      </div>
      {/* </div> */}
    </section>
  );
};

export default Testimonial;
