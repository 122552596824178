import React,{useEffect} from 'react'
import { ServiceSidebar } from './ServiceSidebar'
import { ServicecontactBox } from './ServicecontactBox'
import { RelatedService} from './RelatedService'


import { FaCheckCircle } from 'react-icons/fa';
export const DigitalMarketing = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className="agile pt-top pt-btm">
         <div className="container flex spce-btwn">
               <div className="left">
                    <ServiceSidebar/>
                    <ServicecontactBox/>
                    
               </div>
               <div className="right">
                     <div className="contant-box">
                         <img src="./assets/img/digital-marketingpart.webp" alt="agile information" className='img-fluid mt-35'/>
                         <h3>Digital Marketing</h3>
                         <p>Techsaga offers full suite of digital marketing services with exception marketing skills to drive new customers to grow your business. Our multiple digital marketing services can assist any size of the company in any industry. Techsaga does not rely on one-size-fits-all approach, instead works on creating customized digital marketing plan to help you thrive in the market. Our digital marketing specialists understand your market and goals, and create digital marketing solutions that aligns with your unique business needs. Techsaga deliver substance, not false promises, ensuring to maximize return on investment (ROI).</p>
                         <p>Our holistic marketing approach is focused on driving growth and revenue through Internet Marketing Services:</p>
                         <h5>DIGITAL COMPETITIVE ANALYSIS</h5>
                         <p>Every successful digital marketing campaign involves data driven digital competitive analysis of your website and its competitors. This provides a clear path to measurably improve your website's SEO and PPC performance and generate more leads and sales.</p>
                         <h5>SEARCH ENGINE OPTIMIZATION (SEO)</h5>
                         <p>SEO analysis generate more website traffic, leads, and sales. SEO generates higher keyword rankings on Google, resulting in more qualified website traffic, leads, and sales to boost your business.</p>
                         <h5>PAY-PER-CLICK ADVERTISING (PPC)</h5>
                         <p>Pay-per-click (PPC) advertising helps your company to hold strong position in the top of search engine results. Techsaga helps you to grow your ranking, offers instant visibility and leads. </p>
                         <h5>REMARKETING ADVERTISING</h5>
                         <p>Remarketing or retargeting website visitors to convert prospects into paying customer.</p>
                         <h5>SOCIAL MEDIA MARKETING</h5>
                         <p>Social media is an effective advertising strategy to grow leads and engage your prospects through page likes and following across popular channels like Facebook, Instagram, LinkedIn, Twitter, and more!</p>
                         <h5>REPUTATION MANAGEMENT</h5>
                         <p>Negative reviews for your business can harm reputation for your business. Online reputation management services help you generate positive reviews to build impactful  brand image among your audiences.</p>

                         </div>
                     <RelatedService/>
               </div>
         </div>
    </section>
  )
}

