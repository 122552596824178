import React,{useEffect} from 'react'
// import MyStory from "./Mystory";
export const  Webstorytec = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
    {/* <MyStory /> */}
    <div className='innerAbout photostoryamp'>
      <a href='mystory'>
        <img src='https://resources.techsaga.co.in/wp-content/uploads/2024/01/How-to-Choose-the-Right-Workday-Consultants-2.jpg'  />
      </a>
      <a href='mystory'>
        <img src='https://resources.techsaga.co.in/wp-content/uploads/2024/01/How-to-Choose-the-Right-Workday-Consultants-2.jpg'  />
      </a>
      <a href='mystory'>
        <img src='https://resources.techsaga.co.in/wp-content/uploads/2024/01/How-to-Choose-the-Right-Workday-Consultants-2.jpg'  />
      </a>
      <a href='mystory'>
        <img src='https://resources.techsaga.co.in/wp-content/uploads/2024/01/How-to-Choose-the-Right-Workday-Consultants-2.jpg'  />
      </a>
    </div>
   
    </>
  )
}

// export default Webstorytec
