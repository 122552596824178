import React,{useEffect} from 'react'
import { ServiceSidebar } from './ServiceSidebar'
import { ServicecontactBox } from './ServicecontactBox'
import { RelatedService} from './RelatedService'


import { FaCheckCircle } from 'react-icons/fa';
export const ApplicationDevelopment = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className="agile pt-top pt-btm">
         <div className="container flex spce-btwn">
               <div className="left">
                    <ServiceSidebar/>
                    <ServicecontactBox/>
               </div>
               <div className="right">
                     <div className="contant-box">
                         <img src="./assets/img/application_serivices.jpg" alt="agile information" className='img-fluid mt-35'/>
                         <p>Accelerate your business growth with our next-gen app development practices, offering simplifies business complexities for some of the most successful companies.</p>
                        <h4>WHY US?</h4>
                         <ul>
                           <li><FaCheckCircle/> Optimal resource utilization</li>
                           <li><FaCheckCircle/> Close attention to user experience</li>
                           <li><FaCheckCircle/> Extremely collaborative and outcome-focused</li>
                           <li><FaCheckCircle/> Guaranteed application scaling on demand</li>
                           <li><FaCheckCircle/> Strict adherence to transparency and reporting</li>
                           <li><FaCheckCircle/> Consistent delivery by following CI/CD approaches</li>
                           <li><FaCheckCircle/> Rigorous quality assurance testing before going live</li>
                           <li><FaCheckCircle/> Proven track record in building successful MVP</li>
                         </ul>
                     
                         <p>We are a web and mobile app development company with dedicated development teams and experts focused on building quality software products, specialists in merging creative designs with next-gen app development services. We build fully functional, robust, and scalable B2B apps and B2C apps for a range of digital platforms like mobile, web, desktop, smartwatch, VR and AR.</p>
                         <p>Our application development services include technology consultation, development, testing, support and maintenance. </p>
                         <h4>Application Development Offerings</h4>
                         <h5>Web Application Development</h5>
                         <p>We offer end-to-end web application development services starts from designing, wireframing, prototyping, building, and migrating to new web architecture. We are tech savvy enthusiasts with skilled expertise focus on creating custom web apps. We build secure, scalable, and responsive apps.</p>
                         <ul>
                           <li><FaCheckCircle/> Web Design & Development</li>
                           <li><FaCheckCircle/> SaaS Product Development</li>
                           <li><FaCheckCircle/> Progressive Web Apps (PWA)</li>
                         </ul>
                         <h5>Mobile App Development</h5>
                         <p>Our developers builds fully-fledged android apps and iOS apps, be it native or hybrid platform. Techsaga is a leading mobile app development company that offers a complete cycle of mobile app design, integration, and development services. Our flexible working modules caters to startups to enterprises and build mobile apps that improve user engagement.</p>
                         <ul>
                           <li><FaCheckCircle/> iOS App Development</li>
                           <li><FaCheckCircle/> Android App Development</li>
                           <li><FaCheckCircle/> PCross-Platform App Development</li>
                         </ul>
                         <h5>Other App Development services</h5>
                         <p>We also deliver range of apps with utility, productivity, multimedia and more. We take care of end-to-end development process where we offer apps with better scalability and better performance than existing apps.</p>
                         <ul>
                           <li><FaCheckCircle/> Desktop Application Development</li>
                           <li><FaCheckCircle/> UI/UX Design and Development</li>
                           <li><FaCheckCircle/> Application Maintenance & Support Services</li>
                         </ul>
                     </div>
                     <RelatedService/>
               </div>
         </div>
    </section>
  )
}

