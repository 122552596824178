import React,{useEffect} from 'react'
export const OurSolutions = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
    const solutions = [
    {
      id: 1,
      img: `./assets/icon/compentence.png`,
      heading: `COMPETENCE LEARNING`,
      des: `We aspire to bring proficiency with our learning technique and aptness to correctness.`,
    },
    {
      id: 2,
      img: `./assets/icon/quality.png`,
      heading: `QUALITY ASSURANCE`,
      des: `We behold the art of compliance as paramount and an expert module in our deliveries.`,
    },
    {
      id: 3,
      img: `./assets/icon/ingrity.png`,
      heading: `INTEGRITY BUILDING`,
      des: `We replicate our morals in undertaking implementation & supposed technique. `,
    },
    {
      id: 4,
      img: `./assets/icon/quality-system-assurance.png`,
      heading: `QUALITY CONTROL SYSTEM`,
      des: `We’re not only obligated but assured to benefit the customer with a surprisingly dependable pleasant manipulation of the system.`,
    },
    {
      id: 5,
      img: `./assets/icon/staff.png`,
      heading: `PROFESSIONAL STAFF`,
      des: `We conquer our presence as a need with our experienced staff and highly qualified professionals who are committed to their responsibilities.`,
    },
    {
      id: 6,
      img: `./assets/icon/it-sec.png`,
      heading: `INFORMATION SECURITY MANAGEMENT`,
      des: `We guarantee to offer accessible Information protection to handle growing trouble for predominant scandals placing our clients’ interests at prime.`,
    },
] 
     return (
    <section className="solutions pt-top pt-btm">
        <div className="container">
           <h2>Delivering Excellence in our Solutions</h2>
              <div className="flex spce-btwn">
                {
                 solutions.map((solEl)=>{
                    return(
                        <div className="box" key={solEl.id}>
                     <img src={solEl.img} alt="" />
                     <h5>{solEl.heading}</h5>
                     <p>{solEl.des}</p>
                </div>
                    )
                 })
                }
              </div>
        </div>
    </section>
  )
}
