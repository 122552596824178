import React,{useEffect} from 'react'
import { ServiceSidebar } from './ServiceSidebar'
import { ServicecontactBox } from './ServicecontactBox'
import { RelatedService} from './RelatedService'
import { FaCheckCircle } from 'react-icons/fa';
export const IOTDevelopment = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className="agile pt-top pt-btm">
         <div className="container flex spce-btwn">
               <div className="left">
                    <ServiceSidebar/>
                    <ServicecontactBox/>
                    
               </div>
               <div className="right">
                     <div className="contant-box">
                         <img src="./assets/img/IOT_services1.jpg" alt="agile information" className='img-fluid mt-35'/>
                         <h3>IOT Development</h3>
                         <p>Build your IOT solutions with our full potential in IoT development services for any industry and vertical. We offer a wide range of IoT software development services from engineering system to build an architecture for web and mobile applications for the users of connected systems.</p><br/>
                         <p>IoT Application Development Services</p>
                         <ul>
                           <li><FaCheckCircle/> Accelerating a coherent and seamless user experience across devices and software.</li>
                           <li><FaCheckCircle/> Our IoT applications can aggregate sensor data from devices and process the real-time communication.</li>
                           <li><FaCheckCircle/> Accelerate time-to-ROI and actualize value in your IoT app.</li>
                           <li><FaCheckCircle/> Conducting maintenance and testing services of your IoT applications and services.</li>
                         </ul>
                         <h4>HOW WE CAN HELP-</h4>
                         <h5>Team Augmentation:</h5>
                         <p>Techsaga with right skills and expertise offers an IoT software development services to speed up the launch of your system. Offering a full-suite of services including firmware and IoT application development, data analytics and visualization, etc.</p>
                         <h5>Develop IOT Applications:</h5>
                         <p>With an immense experience of 12+ years, Techsaga develops interfaces across web, mobile, voice and chat for web and mobile applications. These user friendly interfaces help users to easily navigate through your system and make sense of the data it collects.</p>
                         <h5>Custom IoT Software Development Services:</h5>
                         <p>IoT projects in Techsaga’s portfolio are backed by an automated ecosystem and AI based analytics.  From embedded and connectivity solutions to comprehensive platforms, we provide custom IoT development services that enable businesses to manage their operational efficiency. Integrating IOT apps, devices and cloud services provide visibility over departments into a hyper-connected ecosystem built around end users.</p>
                         <h5>IOT Technologies We Use:</h5>
                         <p>We have rich technical skills and broad experience in Internet of Things software development. Our developers constantly thrusting for an innovation and trends to study the latest frameworks and platforms for IoT solution development.</p>
                         <h5>HARDWARE PROGRAMMING LANGUAGES</h5>
                         <ul>
                           <li><FaCheckCircle/> C/C++</li>
                           <li><FaCheckCircle/> Golang</li>
                           <li><FaCheckCircle/> C#</li>
                           <li><FaCheckCircle/> Scala and more</li>
                         </ul>
                         <h5>Hardware Platforms</h5>
                         <ul>
                           <li><FaCheckCircle/> Raspberry Pi</li>
                           <li><FaCheckCircle/> Arduino</li>
                           <li><FaCheckCircle/> BeagleBone</li>
                           <li><FaCheckCircle/> CHIP and more</li>
                         </ul>
                         <h5>Our IoT Service Offerings</h5>
                         <ul>
                           <li><FaCheckCircle/> Data Retrieval</li>
                           <li><FaCheckCircle/> Big Data analysis</li>
                           <li><FaCheckCircle/> Data Processing</li>
                           <li><FaCheckCircle/> Iot Platform Integration</li>
                           <li><FaCheckCircle/> API Development and Integration</li>
                           <li><FaCheckCircle/> Testing and Maintenance</li>
                           <li><FaCheckCircle/>UX Designing for IOT</li>
                           <li><FaCheckCircle/>IOT Application Development Services and Wearables</li>



                         </ul>
                       </div>
                     <RelatedService/>
               </div>
         </div>
    </section>
  )
}

