import React,{useEffect} from 'react'
import { ServiceSidebar } from './ServiceSidebar'
import { ServicecontactBox } from './ServicecontactBox'
import { RelatedService} from './RelatedService'


import { FaCheckCircle } from 'react-icons/fa';
export const ApplicationMaintenance = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className="agile pt-top pt-btm">
         <div className="container flex spce-btwn">
               <div className="left">
                    <ServiceSidebar/>
                    <ServicecontactBox/>
                    
               </div>
               <div className="right">
                     <div className="contant-box">
                         <img src="./assets/img/Application-Maintenance-Services.jpg" alt="agile information" className='img-fluid mt-35'/>
                         <h3>Application Maintenance</h3>
                         <p>Our App Maintenance developers offers full suite of Application Support services. Techsaga connect to business systems to run app maintenance, and make data-driven decisions. We create and design customised solutions to organize, track, and schedule your app maintenance activities. We conduct periodic tests and run maintenance to identify bottlenecks and find areas of improvement. Our experts support to ensure there are no security vulnerabilities in your application system.</p>
                        <h5>Mobile Application Maintenance</h5>
                     <p>We deliver mobile application maintenance services to increase uptime, support larger user bases, optimize server performance, improve UX/UI, comply with the regulations to keep integrated web services running smoothly.</p>
                       <h5>Web Application Maintenance</h5> 
                       <p>Our web application maintenance services include testing, debugging,content update, database maintenance, and real-time monitoring with strict adherence to agreement guidelines.</p>
                       <h5>Application Maintenance Services</h5>
                       <p>We provide on-demand ongoing app maintenance services using proven methodologies to keep your apps running and drive revenue at optimal capacity.</p>
                       <h5>Debugging Services</h5>
                       <p>We consistently monitor and analyze the performance of your app, fix all current minor issues before they can become major during debugging process.</p>
                       <h5>App Monitoring & Support</h5>
                       <p>Our experts provide ongoing application monitoring and support services to ensure that your custom websites and apps are working at optimal performances.</p>
                     </div>
                     <RelatedService/>
               </div>
         </div>
    </section>
  )
}

