import React,{useEffect} from 'react'
import { ServiceSidebar } from './ServiceSidebar'
import { ServicecontactBox } from './ServicecontactBox'
import { RelatedService} from './RelatedService'


import { FaCheckCircle } from 'react-icons/fa';
export const StartupItSupport = () => {
     useEffect(() => {
          window.scrollTo(0, 0)
        }, [])
  return (
    <section className="agile pt-top pt-btm">
         <div className="container flex spce-btwn">
               <div className="left">
                    <ServiceSidebar/>
                    <ServicecontactBox/>
                    
               </div>
               <div className="right">
                     <div className="contant-box">
                         <img src="./assets/img/strtup-side-banner-services.png" alt="agile information" className='img-fluid mt-35'/>
                         <h3>Startup It Support</h3>
                         <p>With a proven track record and 12+ years of experience, Techsaga providing assistance to budding companies to identify, optimize, integrate, secure, and support the next generation systems help them to sustain business growth.Techsaga recommends following IT Security Essentials for Startups</p>
                        <h4>Product Conceptualization</h4>
                      <ul>
                        <li><FaCheckCircle/> Comparative Gap Analysis of Related Products in Market</li>
                           <li><FaCheckCircle/> Identify Pros and Cons for technology stack</li>
                           <li><FaCheckCircle/> Assess / Recommend technology stack</li>
                           <li><FaCheckCircle/> Documentation</li>
                      </ul>
                     <h4>MVP and Prototype</h4>
                     <ul>
                        <li><FaCheckCircle/> Assess / Define MVP features & functionality</li>
                           <li><FaCheckCircle/> Detailed Road-map for Prototype to Launch</li>
                           <li><FaCheckCircle/> Wireframe & Prototype Design</li>
                           <li><FaCheckCircle/> Clickable non-functional Prototype</li>
                           <li><FaCheckCircle/> Assessment of available Prototype</li>
                      </ul>
                         <h4>Architecture Design</h4>
                         <ul>
                        <li><FaCheckCircle/> Architecture Analysis or Evaluation</li>
                           <li><FaCheckCircle/> Define Scalable Architecture</li>
                           <li><FaCheckCircle/> Full Product Architecture Design and Testing</li>
                           <li><FaCheckCircle/> Architecture Validation</li>
                           
                      </ul>
                      <h4>Development</h4>
                      <ul>
                        <li><FaCheckCircle/> Define Project Scope and Plan</li>
                           <li><FaCheckCircle/> Phase-wise development approach</li>
                           <li><FaCheckCircle/> Use of latest technology stack</li>
                           <li><FaCheckCircle/> Unit Testing of interim delivery modules</li>
                           
                      </ul>
                      <h4>Quality Assurance</h4>
                      <ul>
                        <li><FaCheckCircle/> Define Test Plan / Cases from SRS</li>
                           <li><FaCheckCircle/> Functional Testing (Manual and Automated)</li>
                           <li><FaCheckCircle/> Regression Testing</li>
                           <li><FaCheckCircle/> Usability Testing</li>
                           <li><FaCheckCircle/>Acceptance Testing</li>
                           
                      </ul>
                      <h4>Product Re-engineering & Migration of Architecture</h4>
                      <ul>
                        <li><FaCheckCircle/> Assessment of Legacy system</li>
                           <li><FaCheckCircle/> Detailed Gap analysis of existing system and new requirements</li>
                           <li><FaCheckCircle/> Detailed Roadmap for Reengineering</li>
                           <li><FaCheckCircle/> Selection of ideal technology stack for Re-engineering & code migration</li>
                          
                           
                      </ul>
                      <h4>DevOps</h4>
                      <ul>
                        <li><FaCheckCircle/> DevOps Assessment and Strategy Planning</li>
                           <li><FaCheckCircle/> Continuous Integration and Continuous Development</li>
                           <li><FaCheckCircle/> Disaster Recovery</li>
                           <li><FaCheckCircle/> End-to-End Process Implementation</li>
                          
                           
                      </ul>
                      <h4>Performance Testing</h4>
                      <ul>
                        <li><FaCheckCircle/> Performance Analysis </li>
                           <li><FaCheckCircle/> Identification of Bottlenecks</li>
                           <li><FaCheckCircle/> Performance Optimization Consulting</li>
                           <li><FaCheckCircle/> Load Testing and Reporting</li>
                          
                           
                      </ul>
                      <h4>Support and Maintenance</h4>
                      <ul>
                        <li><FaCheckCircle/> 24×7 SLA Driven Support</li>
                           <li><FaCheckCircle/> Ticketing System for Bug Report & Status Tracking</li>
                           <li><FaCheckCircle/> Bug Fixes & Timely Version Releases</li>
                           <li><FaCheckCircle/> Product Optimization and Feautre Enhancement</li>
                          
                           
                      </ul>
                     </div>
                     <RelatedService/>
               </div>
         </div>
    </section>
  )
}

