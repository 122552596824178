import {useEffect} from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


const client = [
    {
      id: 1,
      img:`./assets/img/client1.png`,
      path:`#pro`,

    },
    {
      id: 2,
      img:`./assets/img/client2.png`,

    },
    {
      id: 3,
      img:`./assets/img/client3.png`,

    },
    {
      id: 4,
      img:`./assets/img/client4.png`,
    },
    {
      id: 5,
      img:`./assets/img/client5.png`,
    },

  ];
  
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

export const Client = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section  id='ms5' className="clients pt-top pt-btm">
          <div className="container">
                 <h3>Clients</h3>
                 <Carousel
          responsive={responsive}
          infinite={true}
          autoPlaySpeed={3000}
          arrows={false}
          autoPlay={true}
        >
          {client.map((curEle) => {
            return (
              <>
                <div className="test-item" key={curEle.id}>
                  <img src={curEle.img} alt="clients" />
                </div>
              </>
            );
          })}
        </Carousel>
          </div>
    </section>
  )
}
