import {
    AmpStory,
    AmpStoryPage,
    AmpStoryGridLayer,
    AmpImg,
    AmpHeader,
    AmpParagraph,
    AmpQuote,
    AmpStoryBookend,
    AmpVideo,
    AmpSource,
  } from "react-google-stories";
  import axios from 'axios';
  import { useEffect } from "react";
  
  export const  MyStoryinner = () => {
    useEffect(() => {

      axios.get('https://resources.techsaga.co.in/wp-json/wp/v2/webstory').then((response) => {
         	console.log(response.data[0][0].post_content);
        //  	var res = response.data;
        //  	if(res.status==true)
        //  	{
				// // console.log(res.data.url);
				// // $('meta[name=description]').attr('content',res.data.description);
				// // $('meta[name=keywords]').attr('content',res.data.keywords);
				// // $('meta[name="twitter:card"]').attr('content',res.data.twitter_card);
				// // $('meta[name="twitter:site"]').attr('content',res.data.twitter_site);
				// // $('meta[name="twitter:creator"]').attr('content',res.data.twitter_creater);
				// // $('meta[property="og:title"]').attr('content',res.data.og_title);
				// // $('meta[property="og:description"]').attr('content',res.data.og_description);
				// // $('meta[property="og:image"]').attr('content',res.data.og_image_url);
				// // $('meta[property="og:url"]').attr('content',res.data.url);
				// // $('title').text(res.data.title);
        //  	}
      	}).catch((error) => {
         	console.log(error);
      	});
   });


    return (
      /* Cover page */
      <AmpStory
        title="The BlexZET Story"
        publisher="BlexZET"
        publisher-logo-src="/brandlogo.png"
        poster-portrait-src="/brandlogo.png"
      >
        <AmpStoryPage id="cover">
          <AmpStoryGridLayer template="fill">
            <AmpImg
              src="/hotel1.jpg"
              width="720"
              height="1280"
              layout="responsive"
            ></AmpImg>
          </AmpStoryGridLayer>
          <AmpStoryGridLayer template="vertical">
            <AmpHeader
              className="text-2xl font-bold"
              title="This is a Hotel story"
              level={1}
            />
            <AmpParagraph
              className="font-medium"
              text="By creating using a react-google-stories packege"
            />
          </AmpStoryGridLayer>
        </AmpStoryPage>
  
        {/* Page 1 (Cat): 1 layer (vertical) */}
        <AmpStoryPage id="page1">
          <AmpStoryGridLayer template="vertical">
            <AmpHeader title="Cats" />
            <AmpImg
              src="/hotel1.jpg"
              width="720"
              height="1280"
              layout="responsive"
            ></AmpImg>
            <AmpQuote text="Dogs come when they're called. Cats take a message and get back to you. --Mary Bly" />
          </AmpStoryGridLayer>
        </AmpStoryPage>
  
        {/* Page 2 (Dog): 2 layers (fill + thirds) */}
        <AmpStoryPage id="page2">
          <AmpStoryGridLayer template="fill">
            <AmpImg
              src="assets/dog.jpg"
              width="720"
              height="1280"
              layout="responsive"
            ></AmpImg>
          </AmpStoryGridLayer>
          <AmpStoryGridLayer template="thirds">
            <AmpHeader grid-area="upper-third" title="Dogs" level={1} />
            <AmpParagraph
              grid-area="lower-third"
              text="Dogs were probably the first tame animals. They have accompanied humans for some 10,000 years. Some scientists assert that all dogs, domestic and wild, share a common ancestor in the small South Asian wolf."
            />
          </AmpStoryGridLayer>
        </AmpStoryPage>
  
        {/* Page 3 (Bird): 3 layers (fill + vertical + vertical) + Audio */}
        <AmpStoryPage id="page3" background-audio="assets/bird-singing.mp3">
          <AmpStoryGridLayer template="fill">
            <AmpImg
              src="assets/bird.jpg"
              width="720"
              height="1280"
              layout="responsive"
            ></AmpImg>
          </AmpStoryGridLayer>
          <AmpStoryGridLayer template="vertical">
            <AmpHeader title="Birds" level={1} />
          </AmpStoryGridLayer>
          <AmpStoryGridLayer template="vertical" class="bottom">
            <AmpQuote
              className="bottom"
              text="Dogs come when they're called. Cats take a message and get back to you. --Mary Bly"
            />
          </AmpStoryGridLayer>
        </AmpStoryPage>
  
        {/* Page 4 (Rabbit): 3 layers (fill (video) + vertical + vertical) */}
        <AmpStoryPage id="page4">
          <AmpStoryGridLayer template="fill">
            <AmpVideo
              autoplay
              loop
              width="720"
              height="1280"
              poster="assets/rabbit.jpg"
              layout="responsive"
            >
              <AmpSource src="assets/rabbit.mp4" type="video/mp4" />
            </AmpVideo>
          </AmpStoryGridLayer>
          <AmpStoryGridLayer template="vertical">
            <AmpHeader title="Rabbits" level={1} />
          </AmpStoryGridLayer>
          <AmpStoryGridLayer template="vertical" class="bottom">
            <AmpParagraph text="Rabbits can learn to follow simple voice commands and come when called by name, and are curious and playful." />
          </AmpStoryGridLayer>
        </AmpStoryPage>
  
        {/* Page 5 (Collage): 2 layers + animations */}
        <AmpStoryPage id="page5">
          <AmpStoryGridLayer template="vertical" class="noedge">
            <div className="wrapper">
              <AmpImg
                src="assets/cat.jpg"
                width="720"
                height="1280"
                layout="responsive"
                animate-in="fade-in"
                animate-in-delay="0.4s"
              ></AmpImg>
              <AmpImg
                src="assets/dog.jpg"
                width="720"
                height="1280"
                layout="responsive"
                animate-in="fade-in"
                animate-in-delay="0.6s"
              ></AmpImg>
              <AmpImg
                src="assets/bird.jpg"
                width="720"
                height="1280"
                layout="responsive"
                animate-in="fade-in"
                animate-in-delay=".8s"
              ></AmpImg>
              <AmpImg
                src="assets/rabbit.jpg"
                width="720"
                height="1280"
                layout="responsive"
                animate-in="fade-in"
                animate-in-delay="1s"
              ></AmpImg>
            </div>
          </AmpStoryGridLayer>
          <AmpStoryGridLayer template="vertical" class="center-text">
            <AmpParagraph
              className="banner-text"
              animate-in="whoosh-in-right"
              text="Pets can lower your stress levels!"
            />
          </AmpStoryGridLayer>
        </AmpStoryPage>
  
        {/* Bookend */}
        <AmpStoryBookend src="bookend.json" />
      </AmpStory>
    );
  }
  
 