import React,{useEffect} from 'react'
import { ServiceSidebar } from './ServiceSidebar'
import { ServicecontactBox } from './ServicecontactBox'
import { RelatedService} from './RelatedService'


import { FaCheckCircle } from 'react-icons/fa';
export const IntegratedBrandManagement = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className="agile pt-top pt-btm">
         <div className="container flex spce-btwn">
               <div className="left">
                    <ServiceSidebar/>
                    <ServicecontactBox/>
                    
               </div>
               <div className="right">
                     <div className="contant-box">
                         <img src="./assets/img/integrated-brand-managementpart.jpg" alt="agile information" className='img-fluid mt-35'/>
                         <h3>Integrated Brand Management</h3>
                         <p>At Techsaga, we help companies, brands and businesses by providing a sound marketing strategy based on information and research. Through our effective digital marketing programs, like advertising and public relations programs, we help clients to achieve their goals and provide a return on their marketing investment. As your marketing partner, Techsaga serves you on a project basis with a specific focus in one of our service areas, or anywhere in between. Techsaga is a digital agency with a proven track record of 12+ years. </p>
                         <h5>Brand Management Services</h5>
                         <p>Techsaga is a pioneer digital marketing agency which provides strategic brand management services to support your goals and vision. Our brand integration strategies ensure your company stands out among other competitors while securing customer loyalty. With our effective brand management strategies, we create an affinity for your company's products and services.</p>
                         <h5>Brand Core Identity</h5>
                         <p>The core identity represents the brand's essence and contains the most likely associations to remain constant over time. We understand that every brand is unique and need a core identity to establish relationship with your customers.</p>
                         <h4>OUR PROCESS:</h4>
                         <h5>Understanding Consumer</h5>
                         <p>We research on a client project and technological advancements, brand focus, corporate culture, and the identity that their brand is trying to express through marketing efforts.</p>
                         <h5>Research</h5>
                         <p>Our method involves studies on qualitative situational analysis and market trends measurement.</p>
                         <h5>Integrated Marketing Plan</h5>
                         <p>Can be tweaked depending on the audience and situation. Our expert team strategizes on the best-integrated marketing campaigns.</p>
                         <h5>Profitability</h5>
                         <p>Any strategy or idea is successful only when it generates additional revenues for the organization.</p>
                         
                         
                     
                         
                         
                     </div>
                     <RelatedService/>
               </div>
         </div>
    </section>
  )
}

