import {useEffect} from 'react'
export const Workdayboxleft = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
   <>
      <section className="workdayleft mt-top">
            <div className="container">
                    <div className="box  ">
                       
                        <h4>A relationship with Techsaga simplifies the Workday AMS experience. Tailored services, named resources, and our industry expertise & service warranties pledge that we do things right the first time.</h4>
                    </div>
                    <div id='ms' className="second-box ">
                   
                        <div className="flex spce-btwn align-center">
                       
                        <div className="left">
                             <img src="./assets/icon/digital-services.png" alt="digital service" />
                        </div>
                        <div className="right">
                        <h3>Managed Services</h3>
                          <p>
                            Going live with Workday is just the first step to making the most of your investment. Techsaga is here to partner with you to get you where you want to go. Lean on Techsaga’s Workday experts as an extension of your team, available to support you at your level of need. Key areas of support include configuring business processes, event preparation, optimizing functionality, and much more.
                            {/* <a href="/" className="glb-button">Read More</a> */}
                          </p>
                        </div>
                        </div>
                    </div>
                    <div className="box ">
                        
                        <h4>Did you know? Techsaga's Workday consultants have an average of 6 years' direct Workday experience.</h4>
                    </div>
                    <div id='ms1' className="second-box ">
                  
                        <div className="flex spce-btwn align-center">
                       
                        <div className="left">
                             <img src="./assets/icon/optimization.png" alt="digital service" />
                        </div>
                        <div className="right">
                        <h3>Optimizations</h3>
                          <p>
                              Let Techsaga’s Workday experts guide your platform optimizations — including assessments, roadmapping, and reporting — for maximum ROI on your Workday investment.
                            {/* <a href="/" className="glb-button">Read More</a> */}
                          </p>
                        </div>
                        </div>
                    </div>
                    <div className="box ">
                        <h4>Did you know? Our Workday practice is certified across the entire Workday platform with functional leads capable of supporting any project.</h4>
                    </div>
                    <div id='ms2' className="second-box ">
                   
                        <div className="flex spce-btwn align-center">
                        <div className="left">
                             <img src="./assets/icon/integration.png" alt="digital service" />
                        </div>
                        <div className="right">
                        <h3>Integrations</h3>
                          <p>
                          Techsaga’s Workday Integrations Assurance and Integrations Promise give you the  resources to monitor and troubleshoot existing integrations while also providing unlimited integration break-fix and integration warranties.
                            {/* <a href="/" className="glb-button">Read More</a> */}
                          </p>
                        </div>
                        </div>
                    </div>
                    <div className="box ">  
                        <h4>Did you know? Our Workday Practice has over 50,000 hours of Workday implementation experience ... and we’re just getting started.</h4>
                    </div>
                    <div id='ms3' className="second-box ">
                   
                        <div className="flex spce-btwn align-center">
                       
                        <div className="left">
                             <img src="./assets/icon/testing.png" alt="digital service" />
                        </div>
                        <div className="right">
                        <h3>Implementations</h3>
                          <p>
                              Techsaga’s Workday practice brings together consultants with 20+ years of systems experience and a proven track record of successful Workday deployments across industries. No matter the size, complexity, or industry, we will help your organization deploy the Workday features that best align with your business needs.
                            {/* <a href="/" className="glb-button">Read More</a> */}
                          </p>
                        </div>
                        </div>
                    </div>
                    <div className="box">  
                        <h4>Did you know? The Techsaga team serves as thought leaders in each functional area through Workday conference presentations, marketing activities, and by serving as Product Leads in Workday's partner program.</h4>
                    </div>
                    <div id='ms4' className="second-box">
                   
                        <div className="flex spce-btwn align-center">
                       
                        <div className="left">
                             <img src="./assets/icon/financial-advisor.png" alt="digital service" />
                        </div>
                        <div className="right">
                        <h3>Advisory Services</h3>
                          <p>
                          Techsaga’s Workday Advisory Services help your organization with all facets of strategy, change management and training, and project management.
                            {/* <a href="/" className="glb-button">Read More</a> */}
                          </p>
                        </div>
                        </div>
                    </div>
            </div>
      </section>
 
      </>
  )
}
