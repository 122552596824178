import React,{useEffect} from 'react'
import { ServiceSidebar } from './ServiceSidebar'
import { ServicecontactBox } from './ServicecontactBox'
import { RelatedService} from './RelatedService'


import { FaCheckCircle } from 'react-icons/fa';
export const DevopsMethodology = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className="agile pt-top pt-btm">
         <div className="container flex spce-btwn">
               <div className="left">
                    <ServiceSidebar/>
                    <ServicecontactBox/>
                    
               </div>
               <div className="right">
                     <div className="contant-box">
                         <img src="./assets/img/devops.png" alt="agile information" className='img-fluid mt-35'/>
                         <h3>How We Help with DevOps Services</h3>
                         <p>Techsaga is a leading DevOps consulting service provider for modern software development. Our cornerstone DevOps engineers employs modern tools to incorporate DevOps processes in your organization that complement our frameworks. To accelerate the automation, we optimize the cloud infrastructure and processes while ensuring continuous delivery and continuous integration to quickly get your product to the market. </p>
                         <p>We promise fast-paced development and better time-to-market via industry-leading DevOps services. The main key factor to determine the success of company’s success in any field depends on market-validated DevOps best practices which can bring a feature-rich product to the market in a lower timeframe and cost.</p>
                         <p>Furthermore, DevOps consulting services can help you solve following challenges:</p>
                         <ul>
                           <li><FaCheckCircle/>Lack of cultural balance and communication between software Developers and operations team</li>
                           <li><FaCheckCircle/> Lack of agile delivery to drive software-driven innovation in DevOps services company</li>
                           <li><FaCheckCircle/> The continued existence of silos between Development, IT, and Operations teams</li>
                           <li><FaCheckCircle/> Lack of acceptance on a holistic view of the complete value-chain for continuous delivery tools</li>
                         </ul>
                     
                         <p>DevOps bridges gaps between the primary operational teams to induce better collaboration and enhanced productivity between software development teams and IT operations team.</p>
                         <p>DevOps implementation process comprises of following steps:</p>
                         <ul>
                           <li><FaCheckCircle/>Develop</li>
                           <li><FaCheckCircle/>Build</li>
                           <li><FaCheckCircle/>Test</li>
                           <li><FaCheckCircle/>Deploy</li>
                           <li><FaCheckCircle/>Operate</li>
                           <li><FaCheckCircle/>Monitor</li>
                         </ul>
                         <h4>Assessment and Planning</h4>
                         <p>We create a DevOps Assessment roadmap by visualizing and identifying the traceable KPIs</p>
                         <h4>Pilot Framework Creation</h4>
                         <p>We integrate your existing tools with our robust ecosystem of open source and licensed software for successful integration</p>
                         <h4>Process Implementation</h4>
                         <p>We analyse, design, build , automate , and implement in the specified areas wherever required.</p>
                         <h4>CI/CD Pipeline</h4>
                         <p>We bridge the Dev-Ops gap through continuous development, continuous integration, continuous testing, and continuous deployment and implementation.</p>
                         <h4>Process Automation</h4>
                         <p>We automate the whole process pipeline from code generation to production, covering builds, test cases, quality checks, security, and more.</p>
                         <h4>Security Integration</h4>
                        <p>We run DevSecOps to execute end-to-end security integration through the ‘Security as Code’ mechanism.</p>
                         
                     </div>
                     <RelatedService/>
               </div>
         </div>
    </section>
  )
}

