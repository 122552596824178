import React,{useEffect} from 'react'
import { ServiceSidebar } from './ServiceSidebar'
import { ServicecontactBox } from './ServicecontactBox'
import { RelatedService} from './RelatedService'


import { FaCheckCircle } from 'react-icons/fa';
export const OnlineReputationManagment = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className="agile pt-top pt-btm">
         <div className="container flex spce-btwn">
               <div className="left">
                    <ServiceSidebar/>
                    <ServicecontactBox/>
                    
               </div>
               <div className="right">
                     <div className="contant-box">
                         <img src="./assets/img/Online-reputation-management_Curvearro.jpeg" alt="agile information" className='img-fluid mt-35'/>
                         <h5>Customized ORM Solutions</h5>
                         <p>We provide customized ORM services depending upon your unique needs and requirements. Our only goal is to increase your overall positive web presence.</p>
                        <h5>Cost-Effective ORM Services</h5>
                        <p>We cater to the needs of our valued clients with our quality reputation management services at affordable pricing structures, with 100% satisfaction.</p>
                        <h5>Result-Oriented ORM Packages</h5>
                        <p>We provide relevant ORM plans that give you business-oriented reputation results. Whatever package you choose, we will give our 100% to achieve best results.</p>
                        <h5>Aggressive ORM Activities</h5>
                        <p>We conduct aggressive ORM activities and work 24/7 for you to control and revamp your online brand reputation, create brand awareness and strengthen image.Your reputation is damaged when there is a mismatch between the buzz and the reality. That’s why we prepare a transparent ORM campaign that ensures success of your organization, brand, product or service. Our ORM process consists of five stages including:</p>
                        <ul>
                           <li><FaCheckCircle/> Researching and analyzing your current status</li>
                           <li><FaCheckCircle/> Developing a customized ORM plan</li>
                           <li><FaCheckCircle/> Creating content, PR, social media & SEO strategy</li>
                           <li><FaCheckCircle/>Publishing & promoting positive content</li>
                           <li><FaCheckCircle/>Monitoring ORM campaign</li>
                           <li><FaCheckCircle/>Review generation</li>
                           <li><FaCheckCircle/>Survey campaign management</li>
                           <li><FaCheckCircle/>Reputation monitoring</li>
                           <li><FaCheckCircle/>Social media follower growth service</li>
                           <li><FaCheckCircle/>Review marketing</li>
                           <li><FaCheckCircle/>Search engine optimization (SEO) reputation management</li>
                           <li><FaCheckCircle/>Online reputation repair</li>

                         </ul>
                         <h5>Why Your Business Needs Online Reputation Management?</h5>
                         <p>To stay on top in the Search Results, your business need Online Reputation Management. Online research involves browsing online reviews, so ensure monitoring and review management for business to ensure success.</p>
                         <p>Reputation management services allows you to:</p>
                         <h4>Appeal to High-Intent Prospects</h4>
                         <h5>Customers constantly evaluate the business reviews before making any purchase decision.</h5>
                         <p>Online reviews impacts buyer’s decision. Internet reputation management involves review monitoring and reputation management, which allow you to amplify your positive reviews across online platforms.</p>
                         <h5>Generate Positive Business Reviews</h5>
                         <p>According to statistics, majority of online users never make purchase without online reviews. So Reputation management ensures your business has a steady flow of verified online reviews and your business get honest online reviews from satisfied customers.</p>
                         <h5>Increase Your Customer Loyalty</h5>
                         <p>Brand reputation management helps your bsuiness to build trust with your clients. Reputation management agencies leverage both positive and negative online reviews to ensure transparency and build stronger client relationships.</p>
                         <h5>Attract High-Performing Employees</h5>
                         <p>Internet reputation management allows you to measure employee engagement. Further actionable insights improves the retention and acquisition strategies. If company has a negative reputation online, it fails to attract the right candidates. So reputation management services from Techsaga helps you to focus on holistic growth.</p>
                         <h5>Boost Your SEO Efforts</h5>
                         <p>SEO is an excellent way to build brand awareness in the market. SEO amplify customer voices online and boost your search rankings. More reviews on Google and other review sites, can drives qualified traffic and conversions to your landing pages. Techsaga integrate the user generated content to your Google My Business (GMB) profile and other marketing campaigns to improve your click-through rates and Google rankings.</p>


                         
                     
                        
                         
                     </div>
                     <RelatedService/>
               </div>
         </div>
    </section>
  )
}

