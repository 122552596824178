import React from 'react';
import { FaFacebookF, FaLinkedinIn, FaTwitter, FaMailBulk, FaPhoneAlt, FaMapMarkerAlt,FaCaretDown, FaPinterestP} from 'react-icons/fa';
import {Link, useLocation} from "react-router-dom";
   const sidebar = () => {
      const bar = document.getElementById("sidebar");
      if (bar.style.left === `0px`) {
            bar.style.left = `-100%`;
        }
        else {
            bar.style.left = `0px`;
        }
    }
  
    const Menuopen = () => {
        const bar = document.getElementById("menu");
        bar.classList.toggle('menuopen');
    }
    const menuLinks = document.querySelectorAll(".menu ul li");
    for(let i=0; i<menuLinks.length;i++){
        menuLinks[i].addEventListener("click", () => {
            console.log(menuLinks[i]);
            const bar = document.getElementById("menu");
            bar.classList.remove("menuopen");
        })
    }
    const Subopen = () => {
        const bar = document.getElementById("submenu");
        if (bar.style.height=== `auto`) {
            bar.style.height = `0px`;            
        }
        else {
            bar.style.height = `auto`;
        }
    } 
    const Header = () => {
    const location = useLocation();
    console.log(location.pathname);
    if(location.pathname === "/"){
        document.title = "Home";
    }
    else if(location.pathname === "/about"){
        document.title = "About";
    }
    else if(location.pathname === "/service"){
        document.title = "Service";
    }
    else if(location.pathname === "/industries"){
        document.title = "Industries";
    }
    else if(location.pathname === "/MainBlog"){
        document.title = "Blog";
    }
    else if(location.pathname === "/Contact"){
        document.title = "Contact";
    }
    else if(location.pathname === "/workday"){
        document.title = "workday";
    }
    else if(location.pathname === "/agile"){
        document.title = "Agile Transformation";
    }
    else if(location.pathname === "/start-up-it-support"){
        document.title = "Startup IT Support";
    }
    else if(location.pathname === "/blockchain-development"){
        document.title = "BlockChainDevelopment";
    }
    else if(location.pathname === "/ImprovedROI"){
        document.title = "Improved ROI";
    }
    else if(location.pathname === "/devops-methodology"){
        document.title = "DevopsMethodology";
    }
    else if(location.pathname === "/application-development"){
        document.title = "ApplicationDevelopment";
    }
    else if(location.pathname === "/application-maintenance"){
        document.title = "ApplicationMaintenance";
    }
    else if(location.pathname === "/iot-development"){
        document.title = "IOT Development";
    }
    else if(location.pathname === "/smart-automation-strategies"){
        document.title = "Smart Automation Strategies";
    }
    else if(location.pathname === "/managed-outsourcing-service"){
        document.title = "Managed Outsourcing Service";
    }
    
    else if(location.pathname === "/influential-marketing"){
        document.title = "Influential Marketing";
    }
    else if(location.pathname === "/digital-marketing"){
        document.title = "Digital Marketing";
    }
    else if(location.pathname === "/startup-incubation"){
        document.title = "Startup Incubation";
    }
    else if(location.pathname === "/software-design-and-development"){
        document.title = "Software Design And Development";
    }
   
    else if(location.pathname === "/online-reputation-managment"){
        document.title = "Online Reputation Management";
    }
   
    else if(location.pathname === "/digital-consultation"){
        document.title = " Digital Consultation";
    }
   
    else if(location.pathname === "/hire-developer"){
        document.title = "Hire Developer";
    }
    else if(location.pathname === "/integrated-brand-management"){
        document.title = "Integrated Brand Management";
    }
    else if(location.pathname === "/ReduceInfrastructureCost"){
        document.title = "Reduce Infrastructure Cost";
    }
      var myFunction = () => {
        var element = document.getElementById("menu");
        element.classList.remove("menuopen");
        // Subopen();
        document.getElementById("headerTop").style.height= "0px";
      }
    
    return (
        <>
            <div className="main-header">
                {/* <!-- top bar --> */}
                <div className="topbar">
                    <div className="container">
                        <div className="flex align-center spce-btwn">
                            <div className="flex align-center">
                                <div className="phon-number flex  align-center">
                                    <FaPhoneAlt />
                                    <a href="tel:+1(856) 888-9026">+1(856) 888-9026</a>
                                </div>
                                <div className="mail flex  align-center"> 
                                    <FaMailBulk />
                                    <a href="mailto:info@techsaga.us">info@techsaga.us</a>
                                </div>
                            </div>
                            <div className="address flex  align-center">
                                <FaMapMarkerAlt />
                                <p> 2728 Steece way Leander, TX 78641 United States</p>
                            </div>
                            <div className="social-sections">
                                <a href="https://www.facebook.com/techsaga.us" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
                                <a href="https://www.linkedin.com/company/techsaga_us/" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
                                <a href="https://twitter.com/techsaga_us" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
                                <a href="https://in.pinterest.com/techsaga_us/" target="_blank" rel="noopener noreferrer"><FaPinterestP/></a>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- header --> */}
                <header>
                    <div className="container">
                        <div className="flex spce-btwn align-center">
                            <div className="logo">
                                <Link to="/"><img src="./assets/img/logo-tech.png" alt="logo" className="img-fluid" /></Link>
                            </div>
                            <div className="menu" id="menu">
                                <ul>
                                    <li><Link className={`${location.pathname === "/" ? 'nav_active' : ""}`} to="/" onClick={myFunction}>Home</Link></li>
                                    <li><Link className={`${location.pathname === "/about" ? 'nav_active' : ""}`} to="/about" onClick={myFunction}>About</Link></li>
                                    <li><Link className={`${location.pathname === "/" ? 'nav_active' : ""}`} to="/workday" onClick={myFunction}>Workday</Link></li>
                                    {/* <li><a href="javascript:void(0)">Workday</a></li> */}
                                    <li className="dropdown"><Link className={`dropdown ${location.pathname === "/service" ? 'nav_active' : ""}`} to="/service">Services <FaCaretDown  onClick={() => Subopen()}/></Link>
                                        <div className="submenu" id="submenu">
                                            <div className="flex">
                                                <ul>
                                                    <li><Link className={`${location.pathname === "/agile" ? 'nav_active' : ""}`} to="agile" onClick={myFunction}>Agile Transformation</Link></li>
                                                    {/* <li><a href="javascript:void(0)">Agile Transformation</a></li> */}
                                                    <li><Link className={`${location.pathname === "/devops-methodology" ? 'nav_active' : ""}` } to="devops-methodology" onClick={myFunction}>Devops Methodology</Link></li>
                                                    <li><Link className={`${location.pathname === "/application-development" ? 'nav_active' : ""}`} to="application-development" onClick={myFunction}>Application Development</Link></li>
                                                    <li><Link className={`${location.pathname === "/application-maintenance" ? 'nav_active' : ""}`} to="application-maintenance" onClick={myFunction}>Application Maintenance</Link></li>
                                                    <li><Link className={`${location.pathname === "/iot-development" ? 'nav_active' : ""}`} to="iot-development" onClick={myFunction}>IOT Development</Link></li>
                                                </ul>
                                                <ul>
                                                
                                                <li><Link className={`${location.pathname === "/start-up-it-support" ? 'nav_active' : ""}`} to="start-up-it-support" onClick={myFunction}>Startup It Support</Link></li>
                                               
                                                    <li><Link className={`${location.pathname === "/smart-automation-strategies" ? 'nav_active' : ""}`} to="smart-automation-strategies" onClick={myFunction}>Smart Automation Strategies</Link></li>
                                                    <li><Link className={`${location.pathname === "/managed-outsourcing-service" ? 'nav_active' : ""}`} to="managed-outsourcing-service" onClick={myFunction}>Managed Outsourcing Service</Link></li>
                                                    <li><Link className={`${location.pathname === "/influential-marketing" ? 'nav_active' : ""}`} to="influential-marketing" onClick={myFunction}>Influential Marketing</Link></li>

                                                </ul>
                                                <ul>
                                                
                                                    <li><Link className={`${location.pathname === "/digital-marketing" ? 'nav_active' : ""}`} to="digital-marketing" onClick={myFunction}>Digital Marketing</Link></li>
                                                    {/* <li><a href="javascript:void(0)">Blockchain Development</a></li> */}
                                                    <li><Link className={`${location.pathname === "/blockchain-development" ? 'nav_active' : ""}`} to="blockchain-development" onClick={myFunction}>BlockChain Development</Link></li>
                                                    <li><Link className={`${location.pathname === "/startup-incubation" ? 'nav_active' : ""}`} to="startup-incubation" onClick={myFunction}>Startup Incubation</Link></li>
                                                    <li><Link className={`${location.pathname === "/software-design-and-development" ? 'nav_active' : ""}`} to="software-design-and-development" onClick={myFunction}>Software Design And Development</Link></li>
                                                </ul>
                                                <ul>
                                                
                                                    <li><Link className={`${location.pathname === "/online-reputation-managment" ? 'nav_active' : ""}`} to="online-reputation-managment" onClick={myFunction}>Online Reputation Management</Link></li>
                                                    <li><Link className={`${location.pathname === "/digital-consultation" ? 'nav_active' : ""}`} to="digital-consultation" onClick={myFunction}>Digital Consultation</Link></li>
                                                      {/* <li><a href="">Improved ROI</a></li> */}
                                                    <li><Link className={`${location.pathname === "/hire-developer" ? 'nav_active' : ""}`} to="hire-developer" onClick={myFunction}>Hire Developer</Link></li>
                                                    <li><Link className={`${location.pathname === "/integrated-brand-management" ? 'nav_active' : ""}`} to="integrated-brand-management" onClick={myFunction}>Integrated Brand Management</Link></li>

                                                   {/* <li><a href="">Reduce Infrastructure Cost</a></li> */}
                                                </ul> 
                                            </div>
                                        </div>
                                    </li>
                                    <li><Link className={`${location.pathname === "/industries" ? 'nav_active' : ""}`} to="/industries" onClick={myFunction}>Industries</Link></li>
                                    {/* <li><Link className={`${location.pathname === "/MainBlog" ? 'nav_active' : ""}`} to="/MainBlog">Blog</Link></li> */}
                                    <li><Link className={`${location.pathname === "/Contact" ? 'nav_active' : ""}`} to="/contact" onClick={myFunction}>Contact</Link></li>
                                   
                                </ul>
                            </div>
                            <div className="sidebar-btn">
                                <img src="./assets/icon/menu.png" alt="" onClick={() => Menuopen()}/>
                                <img src="./assets/img/sidebar.webp" alt="sidebar" onClick={() => sidebar()} />
                            </div>
                        </div>
                    </div>
                </header>
                {/* <!-- siderbar --> */}
            </div>
            {/* <!-- siderbar --> */}
           <div className="Minsidebar">
           <section className="sidebar" id="sidebar">
                <div className="sidebar-logo">
                    <Link to="/"><img src="./assets/img/logo-tech.png" alt="logo" /></Link>
                </div>
                <div className="sidebar-des">
                    <p>Techsaga Corporation is integrated into the muse of a multifaceted group inherited with a tested tune of experience and eminent talent. In the herd of our fellow strategists, designers, and innovators, we're obliged to delightful customer satisfaction.</p>
                </div>
                <div className="sidebar-contact">
                    <ul>
                        <li>
                            <FaMapMarkerAlt />
                            <p>2728 Steece way Leander, TX 78641 United States</p>
                        </li>
                        <li>
                            <FaPhoneAlt />
                            <p><a href="tel:+1(856) 888-9026">+1(856) 888-9026</a></p>
                        </li>
                        <li>
                            <FaMailBulk />
                            <p><a href="mailto:info@techsaga.co.in">info@techsaga.co.in</a></p>
                        </li>
                    </ul>
                </div>
                <div className="social-box">
                 <a href="https://www.facebook.com/techsaga.us" target="_blank"><FaFacebookF /></a>
                 <a href="https://www.linkedin.com/company/techsaga_us" target="_blank"><FaLinkedinIn /></a>
                 <a href="https://twitter.com/techsaga_us" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
                <a href="https://in.pinterest.com/techsaga_us/" target="_blank" rel="noopener noreferrer"><FaPinterestP/></a>
                </div>
            </section>
           </div>
        </>
    )
}
export default Header;