import React from 'react'
import {Link} from "react-router-dom";
export const CallToAction = () => {
  return (
    <section className="call_to_action pt-top pt-btm">
         <div className="contianer">
              <h2 className="text-center">
                 Delivering Global Transformation
              </h2>
              <Link className="glb-button" to="/contact">Get Quote Now</Link>
         </div>
    </section>
  )
}
