import React,{useEffect} from 'react'
import { ServiceSidebar } from './ServiceSidebar'
import { ServicecontactBox } from './ServicecontactBox'
import { RelatedService} from './RelatedService'


import { FaCheckCircle } from 'react-icons/fa';
export const BlockChainDevelopment = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className="agile pt-top pt-btm">
         <div className="container flex spce-btwn">
               <div className="left">
                    <ServiceSidebar/>
                    <ServicecontactBox/>
                    
               </div>
               <div className="right">
                     <div className="contant-box">
                         <img src="./assets/img/bg17.jpg" alt="agile information" className='img-fluid mt-35'/>
                         <h3>We ensure the best service.</h3>
                         <p>In recent years, the rapid transformation of the technology world has led blockchain to attain new milestones and has emerged as the most promising technological alternative for most businesses worldwide.</p>
                         <p>Blockchain is flourishing, inevitably over the years. Unarguably, blockchain rules the digital world across all verticals and will continue to thrive in the upcoming years too. This tremendous growth of the blockchain market makes it the most exciting tech trend in this digital era.</p>
                         <p><b>Techsaga</b> is a leading <b>blockchain development company</b> that excels in providing best-in-class blockchain solutions. Techsaga has been serving clientele across the globe with top-notch <b>blockchain development solutions</b> for diverse business models since 2012. Our squad of blockchain developers focuses on developing high-grade <b>blockchain development solutions</b> for different industries, such as insurance, eCommerce, health care, banking, real estate, travel, the manufacturing sector, the hospitality industry, etc.</p>
                         <p><b>At Techsaga,</b> we offer comprehensive <b>blockchain development services</b> to our clients according to their business requirements and deliver felicitous blockchain solutions to them. Techsaga is a pioneer with notable <b>blockchain development services</b> that offer transparency, high-level security, and efficiency. To date, proficient blockchain developers at Techsaga employ highly advanced technologies to design robust and scalable blockchain solutions.</p>
                         {/* <ul>
                           <li><FaCheckCircle/> Sed ut perspiciatis unde omnis iste natus error</li>
                           <li><FaCheckCircle/> Nemo enim ipsam voluptatem quia voluptas sit aspernatur</li>
                           <li><FaCheckCircle/> Accusamus et iusto odio dignissimos ducimus qui blanditiis</li>
                           <li><FaCheckCircle/> Nam libero tempore, cum soluta nobis est eligendi optio cumque</li>
                         </ul> */}
                     
                         <p>While continuously focusing on quality, our professionals develop <b>Blockchain-powered solutions</b> with avant-garde technologies.Techsaga, being a prominent blockchain development company, is adept at implementing next-gen technologies in all its blockchain solutions. Therefore, leveraging the robust decentralised platforms helped clients to reap the benefits. So, it is no surprise that Techsaga is a world-renowned blockchain solution provider with its agile development process, cost-effective development, quality assurance, etc.</p>
                         <p>With more than 12+ years of experience and 50+, blockchain specialists, Techsaga helps clients get ahead of their fierce competitors. Our eye-catching expertise is complemented by extensive experience in transforming new futuristic blockchain business ideas into reality through customizable and eye-catching digital solutions.</p>
                         <p>With our expertise, we stand apart from others in the blockchain world. Hungry for innovation, our squad efficiently helps businesses reach new levels in the decentralized world.</p>
                         <p>Start your successful blockchain journey with us.</p>
                         
                     </div>
                     <RelatedService/>
               </div>
         </div>
    </section>
  )
}

