import React,{useEffect} from 'react'
import { ServiceSidebar } from './ServiceSidebar'
import { ServicecontactBox } from './ServicecontactBox'
import { RelatedService} from './RelatedService'


import { FaCheckCircle } from 'react-icons/fa';
export const SmartAutomationStrategies = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className="agile pt-top pt-btm">
         <div className="container flex spce-btwn">
               <div className="left">
                    <ServiceSidebar/>
                    <ServicecontactBox/>
                    
               </div>
               <div className="right">
                     <div className="contant-box">
                         <img src="./assets/img/Smart-Automation.jpg" alt="agile information" className='img-fluid mt-35'/>
                         <h3>Smart Automation Strategies:</h3>
                         <p>Unleash automation potential with Techsaga to drive your business forward. Techsaga with 12+ years of expertise, can help you improve efficiency and productivity in many ways. By applying automation, companies can scale up the benefits across the enterprise. Due to the lack of right expertise and skill sets, automation investments are not proliferating and not reaching their true potential. Our enterprise-wide approach supported through organizational change and continual reskilling.</p>
                         <ul>
                           <li><FaCheckCircle/> Offers smart intelligent automation services for large global clients.</li>
                           <li><FaCheckCircle/> We provide excellent, agile solutions that are right for our clients’ needs. </li>
                           <li><FaCheckCircle/> Troubleshoot the problems, and we help our clients to understand what areas of their business they can automate.</li>
                           <li><FaCheckCircle/> We enable clients to become self-sufficient.</li>
                           <li><FaCheckCircle/> We don’t change systems; we’re skilled in seamlessly automating them with the right tools and software.</li>
                           <li><FaCheckCircle/> We build in-house RPA experience to allow and help companies to establish centres of excellence.</li>
                         </ul>
                         <h5>CONSULTING</h5>
                        <p>In this stage, Techsaga consultants designs the strategic roadmap and assess the maturity assessment that align with your business priorities. Our Smart Automation consultants help you identify areas of automation and build enterprise-wide automation strategy.</p>
                        <h5>DESIGN</h5>
                        <p>Our Smart Automation experts design automation architecture and develop a custom framework for implementation.</p>
                        <h5>IMPLEMENTATION</h5>
                        <p>Our experts employ next-gen tools like AI, ML and cognitive technologies to support pilots and to scale beyond pilots. We share extensive expertise across a variety of business, IT and industrial settings.</p>
                        <h5>SUPPORT</h5>
                        <p>Our support team helps you with optimize solutions for automation management, disaster recovery, risk management, and opportunity discovery.</p> 


                      
                         
                     </div>
                     <RelatedService/>
               </div>
         </div>
    </section>
  )
}

