import React,{useEffect} from 'react'
import { ServiceSidebar } from './ServiceSidebar'
import { ServicecontactBox } from './ServicecontactBox'
import { RelatedService} from './RelatedService'


import { FaCheckCircle } from 'react-icons/fa';
export const DigitalConsultation = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className="agile pt-top pt-btm">
         <div className="container flex spce-btwn">
               <div className="left">
                    <ServiceSidebar/>
                    <ServicecontactBox/>
                    
               </div>
               <div className="right">
                     <div className="contant-box">
                         <img src="./assets/img/digital-consulting-uai.jpg" alt="agile information" className='img-fluid mt-35'/>
                         <h3>Digital Consultation</h3>
                         <p>Techsaga’s consulting services offers strategy to execution, leveraging modern interfaces and technologies to improve your digital customer experience.</p>
                         <p>With our solid digital strategy, we provide client-centric solutions coupled with digital experience design services that put the customer at the center of every interaction. Our focus is to increase customer engagement through new-age interfaces through digital modernization. Our strategy enables organizations to adopt an analytically enabled mindset to ensure market validity and acceptance.</p>
                         <p>Here’s how we can help:</p>
                        <h5>Digital Strategy</h5>
                        <p>Techsaga partners with its clients to create a winning digital strategy to develop smarter products and personalized services. Leverages the capabilities and technologies ensure that you reach business goals in the digital world.</p>
                        <h5>Digital Modernization</h5>
                        <p>Digital modernization with the modern solutions propels your business forward with the right digital channels. Techsaga harnesses the power of data and artificial intelligence, modernize core technology and capitalize on new technology, helping organizations to thrive in a digital-age.</p>
                        <h5>Digital Design & Enablement</h5>
                        <p>Our digital support comprises of modern digital interactions that maximize technology and innovation across customer experiences.Techsaga focuses on maximizing interactions with customers, supports both sales and servicing efforts by leveraging modern applications to enhance end-to-end customer experience.</p>
                     
                        
                         
                     </div>
                     <RelatedService/>
               </div>
         </div>
    </section>
  )
}

