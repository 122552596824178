import React,{useEffect} from 'react'
import { ServiceSidebar } from './ServiceSidebar'
import { ServicecontactBox } from './ServicecontactBox'
import { RelatedService} from './RelatedService'
import { FaCheckCircle } from 'react-icons/fa';
export const AgileTransformation = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <section className="agile pt-top pt-btm">
         <div className="container flex spce-btwn">
               <div className="left">
                    <ServiceSidebar/>
                    <ServicecontactBox/>
                    
               </div>
               <div className="right">
                     <div className="contant-box">
                         <img src="./assets/img/agile12.png" alt="agile information" className='img-fluid mt-35'/>
                         <h3><b>BE A CHANGE: </b>Start your Agile Transformation Journey today</h3>
                         <p>Techsaga entails the power of agile transformation, driving an organization's success. Being an agile leader can help your business thrive in a disrupted ecosystem amidst a pandemic. Techsaga, with its cornerstone values, empowers organizations and collaborates to create a customized agile transformation program. Typical agile transformation services include:</p>
                         {/* <p>Cras enim urna, interdum nec porttitor vitae, sollicitudin eu eros. Praesent eget mollis nulla, non lacinia urna. Donec sit amet neque auctor, ornare dui rutrum, condimentum justo. Duis dictum, ex accumsan eleifend eleifend, ex justo aliquam nunc, in ultrices ante quam eget massa. Sed scelerisque, odio eu tempor pulvinar, magna tortor finibus lorem.</p> */}
                         <ul>
                           <li><FaCheckCircle/> Assessments of the current state of an organization</li>
                           <li><FaCheckCircle/> Creating a roadmap and a vision</li>
                           <li><FaCheckCircle/> Creation and launch of the agile transformation program</li>
                           <li><FaCheckCircle/> The identification of valuable metrics</li>
                           <li><FaCheckCircle/> Assisting in the formation of internal agile advocates</li>
                           <li><FaCheckCircle/> Change management</li>
                           <li><FaCheckCircle/>Engagement with an agile consultant</li>
                         </ul>
                         <h4>Agility: Change driver for process and technology</h4>
                         <p>Through business transformation initiatives, Techsaga has helped industry leaders to excel, adapt, and grow. Partnering with us enables you to transform your organization's culture into high-performing teams. Through actionable growth plans, Techsaga strengthens your organization to lead today’s complex initiatives.</p>
                         <p>Techsaga’s Agile Transformation Services help companies achieve optimum agility to accelerate business change. We implement lean-agile practices to bring about change and transform ways of working throughout the enterprise. We combine process and cultural transformation through a seamless end-to-end approach. Assisting organisations in delivering high-quality software and improving responsiveness to business and customer demands.
                        </p>
                        <p>Since 2012, we have been a leading agile development company, co-creating solutions using agile development. As a pioneer in Agile transformation, with services for business and technology, Techsaga harnesses the power of experienced teams for agile coaching and delivery in the industry.</p>
                        <h4>Development and implementation</h4>
                        <p>We transition from traditional process delivery to modern agile and DevOps for iterative and rapid releases while driving business.</p>
                        <h4>Coaching and advisory</h4>
                        <p>Our cross-functional and scalable teams guide the enterprise in the adoption of Agile principles and practices. High-performing teams deliver measurable business outcomes while continuously improving through</p>
                        <ul>
                           <li><FaCheckCircle/> Companies are expected to change in pursuit of business agility.</li>
                           <li><FaCheckCircle/> Become more flexible.</li>
                           <li><FaCheckCircle/> Better response to market change</li>
                           <li><FaCheckCircle/> Reduce time to market.</li>
                           
                         </ul>
                         <p>Adopting this methodology and reaping full benefits depends on the company’s willingness to change and its agile maturity. Techsaga experts provide Agile Transformation services for companies that are willing to embrace new ideas and undergo a transition encompassing</p>
                         <ul>
                           <li><FaCheckCircle/> The radical shift in attitudes, mindsets, and culture</li>
                           <li><FaCheckCircle/> Learning and being open to change</li>
                           <li><FaCheckCircle/> Applying new knowledge into practice</li>
                           <li><FaCheckCircle/> the product that meets the needs of the user</li>
                           <li><FaCheckCircle/> Increase in revenue share.</li>
                         </ul>
                         
                     </div>
                     <RelatedService/>
               </div>
         </div>
    </section>
  )
}

